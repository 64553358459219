<template>
  <section id="fast-slowmover-products">
    <b-card>
      <b-row>
        <b-col xl="2" md="2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showQtyDiscountedProducts" spinner-variant="primary" variant="transparent" blur="3px"
      rounded="sm">
      <b-row>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div>
                <h5 class="mb-0">Turnover Change</h5>
                <small class="text-muted">Qty Discounted Products</small>
              </div>
              <div class="text-right">
                <h4 class="mb-0">
                  <span :class="{
                    'text-success': qtyDiscountedProductsTurnoverChange > 0,
                    'text-danger': qtyDiscountedProductsTurnoverChange < 0,
                  }">
                    {{ qtyDiscountedProductsTurnoverChange > 0 ? '+' : '' }}{{
                      qtyDiscountedProductsTurnoverChange.toFixed(2) }}%
                  </span>
                </h4>
                <small class="text-muted">
                  Current Year: € {{ qtyDiscountedProductsTurnoverCurrentYear | formatNumber }}<br />
                  Previous Year: € {{ qtyDiscountedProductsTurnoverPrevYear | formatNumber }}
                </small>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div>
                <h5 class="mb-0">Qty Sold Change</h5>
                <small class="text-muted">Qty Discounted Products</small>
              </div>
              <div class="text-right">
                <h4 class="mb-0">
                  <span :class="{
                    'text-success': qtyDiscountedProductsQtySoldChange > 0,
                    'text-danger': qtyDiscountedProductsQtySoldChange < 0,
                  }">
                    {{ qtyDiscountedProductsQtySoldChange > 0 ? '+' : '' }}{{
                      qtyDiscountedProductsQtySoldChange.toFixed(2) }}%
                  </span>
                </h4>
                <small class="text-muted">
                  Current Year: {{ qtyDiscountedProductsQtySoldCurrentYear | formatNumber }}<br />
                  Previous Year: {{ qtyDiscountedProductsQtySoldPrevYear | formatNumber }}
                </small>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div>
                <h5 class="mb-0">Margin Change</h5>
                <small class="text-muted">Qty Discounted Products</small>
              </div>
              <div class="text-right">
                <h4 class="mb-0">
                  <span :class="{
                    'text-success': qtyDiscountedProductsMarginChange > 0,
                    'text-danger': qtyDiscountedProductsMarginChange < 0,
                  }">
                    {{ qtyDiscountedProductsMarginChange > 0 ? '+' : '' }}{{
                      qtyDiscountedProductsMarginChange.toFixed(2) }}%
                  </span>
                </h4>
                <small class="text-muted">
                  Current Year: € {{ qtyDiscountedProductsMarginCurrentYear | formatNumber }}<br />
                  Previous Year: € {{ qtyDiscountedProductsMarginPrevYear | formatNumber }}
                </small>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <b-overlay :show="showQtyDiscountedProductsTable" spinner-variant="primary" variant="transparent" blur="3px"
      rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Qty Discounted Products
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-fastmover-products" />
          </h4>
          <b-popover target="popover-fastmover-products" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative"
          :current-page="qtyDiscountedProductsTable.currentPage" :items="qtyDiscountedProductsTable.items"
          :fields="qtyDiscountedProductsTable.fields" :sort-by.sync="qtyDiscountedProductsTable.sortBy"
          :sort-desc.sync="qtyDiscountedProductsTable.sortDesc"
          :sort-direction="qtyDiscountedProductsTable.sortDirection" :filter="qtyDiscountedProductsTable.filter"
          :filter-included-fields="qtyDiscountedProductsTable.filterOn"
          @sort-changed="sortChangedQtyDiscountedProducts">
          <template #cell(qty_change_percent)="data">
            <span :class="{
              'text-danger': data.item.qty_change_percent < 0,
              'text-success': data.item.qty_change_percent > 0,
            }">
              {{ data.item.qty_change_percent !== null ? `${data.item.qty_change_percent > 0 ? '+' :
                ''}${$formatNumber(data.item.qty_change_percent)}%` : '' }}
            </span>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="qtyDiscountedProductsTable.currentPage"
              :total-rows="qtyDiscountedProductsTable.totalRows" first-number last-number prev-class="prev-item"
              next-class="next-item" class="mb-0" @change="handleQtyDiscountedProductsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-overlay :show="showFastmoverProducts" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Fastmover products
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-fastmover-products" />
          </h4>
          <b-popover target="popover-fastmover-products" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportFastmoverProductsToExcelStart" spinner-variant="primary" variant="transparent"
              blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                @click="exportFastmoverProductsToExcel" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="fastmoverProductsTable.currentPage"
          :items="fastmoverProductsTable.items" :fields="fastmoverProductsTable.fields"
          :sort-by.sync="fastmoverProductsTable.sortBy" :sort-desc.sync="fastmoverProductsTable.sortDesc"
          :sort-direction="fastmoverProductsTable.sortDirection" :filter="fastmoverProductsTable.filter"
          :filter-included-fields="fastmoverProductsTable.filterOn" @sort-changed="sortChangedFastmoverProducts" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="fastmoverProductsTable.currentPage" :total-rows="fastmoverProductsTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleFastmoverProductsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-overlay :show="showNotSoldProductsLast6Month" spinner-variant="primary" variant="transparent" blur="3px"
      rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Not sold products in the last 6 months
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-not-sold-products-6-month" />
          </h4>
          <b-popover target="popover-not-sold-products-6-month" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportNotSoldProductsLast6MonthToExcelStart" spinner-variant="primary"
              variant="transparent" blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                @click="exportNotSoldProductsLast6MonthToExcel" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative"
          :current-page="notSoldProductsLast6MonthTable.currentPage" :items="notSoldProductsLast6MonthTable.items"
          :fields="notSoldProductsLast6MonthTable.fields" :sort-by.sync="notSoldProductsLast6MonthTable.sortBy"
          :sort-desc.sync="notSoldProductsLast6MonthTable.sortDesc"
          :sort-direction="notSoldProductsLast6MonthTable.sortDirection" :filter="notSoldProductsLast6MonthTable.filter"
          :filter-included-fields="notSoldProductsLast6MonthTable.filterOn"
          @sort-changed="sortChangedNotSoldProductsLast6Month" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="notSoldProductsLast6MonthTable.currentPage"
              :total-rows="notSoldProductsLast6MonthTable.totalRows" first-number last-number prev-class="prev-item"
              next-class="next-item" class="mb-0" @change="handleNotSoldProductsLast6MonthTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-overlay :show="showNotSoldProductsLastYear" spinner-variant="primary" variant="transparent" blur="3px"
      rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Not sold products in the last year
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-not-sold-products-year" />
          </h4>
          <b-popover target="popover-not-sold-products-year" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportNotSoldProductsLastYearToExcelStart" spinner-variant="primary"
              variant="transparent" blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                @click="exportNotSoldProductsLastYearToExcel" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative"
          :current-page="notSoldProductsLastYearTable.currentPage" :items="notSoldProductsLastYearTable.items"
          :fields="notSoldProductsLastYearTable.fields" :sort-by.sync="notSoldProductsLastYearTable.sortBy"
          :sort-desc.sync="notSoldProductsLastYearTable.sortDesc"
          :sort-direction="notSoldProductsLastYearTable.sortDirection" :filter="notSoldProductsLastYearTable.filter"
          :filter-included-fields="notSoldProductsLastYearTable.filterOn"
          @sort-changed="sortChangedNotSoldProductsLastYear" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="notSoldProductsLastYearTable.currentPage"
              :total-rows="notSoldProductsLastYearTable.totalRows" first-number last-number prev-class="prev-item"
              next-class="next-item" class="mb-0" @change="handleNotSoldProductsLastYearTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BCard,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';
import ECharts from 'vue-echarts';
import * as XLSX from 'xlsx';

import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const sixMonthsAgo = new Date();
sixMonthsAgo.setMonth(currentDate.getMonth() - 5);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BCard,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    ECharts,
  },
  data() {
    return {
      showQtyDiscountedProducts: true,
      showQtyDiscountedProductsTable: true,
      showFastmoverProducts: true,
      showNotSoldProductsLast6Month: true,
      showNotSoldProductsLastYear: true,
      showExportFastmoverProductsToExcelStart: false,
      showExportNotSoldProductsLast6MonthToExcelStart: false,
      showExportNotSoldProductsLastYearToExcelStart: false,
      oldDateRange: `${sixMonthsAgo.getFullYear()}-${formatMonth(sixMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${sixMonthsAgo.getFullYear()}-${formatMonth(sixMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      qtyDiscountedProductsTurnoverCurrentYear: 0,
      qtyDiscountedProductsTurnoverPrevYear: 0,
      qtyDiscountedProductsTurnoverChange: 0,
      qtyDiscountedProductsQtySoldCurrentYear: 0,
      qtyDiscountedProductsQtySoldPrevYear: 0,
      qtyDiscountedProductsQtySoldChange: 0,
      qtyDiscountedProductsMarginCurrentYear: 0,
      qtyDiscountedProductsMarginPrevYear: 0,
      qtyDiscountedProductsMarginChange: 0,
      queryParamsQtyDiscountedProducts: {},
      queryParamsFastmoverProducts: {},
      queryParamsNotSoldProductsLast6Month: {},
      queryParamsNotSoldProductsLastYear: {},
      qtyDiscountedProductsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'price',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'price',
            label: 'price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'qty_sold_current_year', label: 'qty sold current year', sortable: true },
          { key: 'qty_sold_prev_year_ytd', label: 'qty sold prev year', sortable: true },
          {
            key: 'qty_change_percent',
            label: 'qty sold change',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)}%`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      fastmoverProductsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'orders_count',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'onlineshop', label: 'onlineshop', sortable: true },
          {
            key: 'purchase_price',
            label: 'purchase price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'sales_price',
            label: 'sales price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
          { key: 'overall_stock_qty', label: 'overall stock qty', sortable: true },
          { key: 'min_stock_qty', label: 'min stock qty', sortable: true },
          { key: 'orders_count', label: 'orders count', sortable: true },
          { key: 'class', label: 'class', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      notSoldProductsLast6MonthTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'sales_price',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'onlineshop', label: 'onlineshop', sortable: true },
          {
            key: 'purchase_price',
            label: 'purchase price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'sales_price',
            label: 'sales price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
          { key: 'overall_stock_qty', label: 'overall stock qty', sortable: true },
          {
            key: 'last_order_date',
            label: 'last order date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'orders_count', label: 'orders count', sortable: true },
          { key: 'class', label: 'class', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      notSoldProductsLastYearTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'sales_price',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'onlineshop', label: 'onlineshop', sortable: true },
          {
            key: 'purchase_price',
            label: 'purchase price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'sales_price',
            label: 'sales price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
          { key: 'overall_stock_qty', label: 'overall stock qty', sortable: true },
          {
            key: 'last_order_date',
            label: 'last order date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'orders_count', label: 'orders count', sortable: true },
          { key: 'class', label: 'class', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParamsFastmoverProducts.from_date = dateRange[0];
    this.queryParamsFastmoverProducts.to_date = dateRange[1];

    try {
      await this.getQtyDiscountedProducts();
      await this.getQtyDiscountedProductsTable();
      await this.getFastmoverProducts();
      await this.getNotSoldProductsLast6Month();
      await this.getNotSoldProductsLastYear();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getQtyDiscountedProducts() {
      this.showQtyDiscountedProducts = true;
      try {
        const response = await this.makeRequest(
          `${process.env.VUE_APP_ROOT_API}/qty-discounted-products/`,
        );
        const results = response.data.results;

        // Turnover
        this.qtyDiscountedProductsTurnoverCurrentYear = results[1].total_turnover;
        this.qtyDiscountedProductsTurnoverPrevYear = results[0].total_turnover;
        // Calculate the turnover change
        this.qtyDiscountedProductsTurnoverChange = ((this.qtyDiscountedProductsTurnoverCurrentYear - this.qtyDiscountedProductsTurnoverPrevYear) / this.qtyDiscountedProductsTurnoverPrevYear) * 100;

        // Qty Sold
        this.qtyDiscountedProductsQtySoldCurrentYear = results[1].total_qty_sold;
        this.qtyDiscountedProductsQtySoldPrevYear = results[0].total_qty_sold;
        // Calculate the qty sold change
        this.qtyDiscountedProductsQtySoldChange = ((this.qtyDiscountedProductsQtySoldCurrentYear - this.qtyDiscountedProductsQtySoldPrevYear) / this.qtyDiscountedProductsQtySoldPrevYear) * 100;

        // Margin
        this.qtyDiscountedProductsMarginCurrentYear = results[1].total_margin;
        this.qtyDiscountedProductsMarginPrevYear = results[0].total_margin;
        // Calculate the margin change
        this.qtyDiscountedProductsMarginChange = ((this.qtyDiscountedProductsMarginCurrentYear - this.qtyDiscountedProductsMarginPrevYear) / this.qtyDiscountedProductsMarginPrevYear) * 100;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showQtyDiscountedProducts = false;
      }
    },
    async getQtyDiscountedProductsTable() {
      this.showQtyDiscountedProductsTable = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/qty-discounted-products-detail/`, this.queryParamsQtyDiscountedProducts);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.qtyDiscountedProductsTable.items = results;
          this.qtyDiscountedProductsTable.totalRows = results[0].count * 2;
        }
        else {
          this.qtyDiscountedProductsTable.items = [];
          this.qtyDiscountedProductsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showQtyDiscountedProductsTable = false;
      }
    },
    async getFastmoverProducts() {
      this.showFastmoverProducts = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/fastmover-products/`, this.queryParamsFastmoverProducts);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.fastmoverProductsTable.items = results;
          this.fastmoverProductsTable.totalRows = results[0].count * 2;
        }
        else {
          this.fastmoverProductsTable.items = [];
          this.fastmoverProductsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showFastmoverProducts = false;
      }
    },
    async getNotSoldProductsLast6Month() {
      this.showNotSoldProductsLast6Month = true;
      // 6 months ago
      this.queryParamsNotSoldProductsLast6Month.to_date = new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0];
      this.queryParamsNotSoldProductsLast6Month.min_stock_qty = 1;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/products-not-sold/`, this.queryParamsNotSoldProductsLast6Month);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.notSoldProductsLast6MonthTable.items = results;
          this.notSoldProductsLast6MonthTable.totalRows = results[0].count * 2;
        }
        else {
          this.notSoldProductsLast6MonthTable.items = [];
          this.notSoldProductsLast6MonthTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showNotSoldProductsLast6Month = false;
      }
    },
    async getNotSoldProductsLastYear() {
      this.showNotSoldProductsLastYear = true;
      // 1 year ago
      this.queryParamsNotSoldProductsLastYear.to_date = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/products-not-sold/`, this.queryParamsNotSoldProductsLastYear);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.notSoldProductsLastYearTable.items = results;
          this.notSoldProductsLastYearTable.totalRows = results[0].count * 2;
        }
        else {
          this.notSoldProductsLastYearTable.items = [];
          this.notSoldProductsLastYearTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showNotSoldProductsLastYear = false;
      }
    },
    async sortChangedQtyDiscountedProducts(value) {
      if (value.sortDesc === true) {
        this.queryParamsQtyDiscountedProducts.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsQtyDiscountedProducts.ordering = value.sortBy;
      }
      await this.getQtyDiscountedProductsTable();
    },
    async sortChangedFastmoverProducts(value) {
      if (value.sortDesc === true) {
        this.queryParamsFastmoverProducts.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsFastmoverProducts.ordering = value.sortBy;
      }
      await this.getFastmoverProducts();
    },
    async sortChangedNotSoldProductsLast6Month(value) {
      if (value.sortDesc === true) {
        this.queryParamsNotSoldProductsLast6Month.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsNotSoldProductsLast6Month.ordering = value.sortBy;
      }
      await this.getNotSoldProductsLast6Month();
    },
    async sortChangedNotSoldProductsLastYear(value) {
      if (value.sortDesc === true) {
        this.queryParamsNotSoldProductsLastYear.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsNotSoldProductsLastYear.ordering = value.sortBy;
      }
      await this.getNotSoldProductsLastYear();
    },
    async handleQtyDiscountedProductsTablePageChange(value) {
      this.queryParamsQtyDiscountedProducts.page = value;
      await this.getQtyDiscountedProductsTable();
    },
    async handleFastmoverProductsTablePageChange(value) {
      this.queryParamsFastmoverProducts.page = value;
      await this.getFastmoverProducts();
    },
    async handleNotSoldProductsLast6MonthTablePageChange(value) {
      this.queryParamsNotSoldProductsLast6Month.page = value;
      await this.getNotSoldProductsLast6Month();
    },
    async handleNotSoldProductsLastYearTablePageChange(value) {
      this.queryParamsNotSoldProductsLastYear.page = value;
      await this.getNotSoldProductsLastYear();
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParamsFastmoverProducts.from_date = dateRange[0];
        this.queryParamsFastmoverProducts.to_date = dateRange[1];

        await this.getQtyDiscountedProducts();
        await this.getFastmoverProducts();

        this.oldDateRange = this.dateRange;
      }
    },
    async exportFastmoverProductsToExcel() {
      try {
        this.showExportFastmoverProductsToExcelStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-fastmover-products/`, this.queryParamsFastmoverProducts);
        const exportedData = response.data.results;
        this.showExportFastmoverProductsToExcelStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'FastmoverProducts.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async exportNotSoldProductsLast6MonthToExcel() {
      try {
        this.showExportNotSoldProductsLast6MonthToExcelStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-products-not-sold/`, this.queryParamsNotSoldProductsLast6Month);
        const exportedData = response.data.results;
        this.showExportNotSoldProductsLast6MonthToExcelStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'NotSoldProductsLast6Month.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async exportNotSoldProductsLastYearToExcel() {
      try {
        this.showExportNotSoldProductsLastYearToExcelStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-products-not-sold/`, this.queryParamsNotSoldProductsLastYear);
        const exportedData = response.data.results;
        this.showExportNotSoldProductsLastYearToExcelStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'NotSoldProductsLastYear.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>